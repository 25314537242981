<template>
  <v-container>
    <v-row class="pt-6" justify="space-around">
      <!-- <v-col v-for="cat in categories" :key="cat.title"> -->
      <div class="px-auto" v-for="cat in categories" :key="cat.title">
        <v-hover v-slot="{ hover }">
          <v-card
            @click="show(cat)"
            :class="`elevation-${hover ? 24 : 6}`"
            class="transition-swing"
            max-width="35vw"
            width="200"
          >
            <v-img
              :src="require(`@/assets/dresscode/${cat.header}.jpg`)"
              max-height="175"
              aspect-ratio="1"
              position="center"
              class="grey darken-4"
            >
            </v-img>
            <v-card-title
              class="text-h6"
              :class="cat.show ? 'primary--text' : ''"
            >
              {{ cat.title }}
              <v-spacer></v-spacer>

              <v-icon :class="cat.show ? 'primary--text' : ''">{{
                cat.show ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-card-title>
          </v-card>
        </v-hover>
      </div>
      <!-- </v-col> -->
    </v-row>

    <div v-for="cat in categories" :key="cat.title">
      <transition
        :name="cat.show ? 'scroll-y-transition' : 'scroll-y-reverse-transition'"
      >
        <v-row class="pt-12" v-show="cat.show">
          <v-col
            v-for="n in cat.count"
            :key="n"
            class="d-flex child-flex"
            cols="4"
          >
            <v-img
              :src="require(`@/assets/dresscode/${cat.header}/${n}.jpg`)"
              aspect-ratio="1"
              class="grey lighten-2"
              @click="open(cat, n)"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
      </transition>
    </div>
          <v-overlay v-if="opened" :value="selected.overlay != -1" @click="close">
            <v-carousel v-model="selected.overlay" hide-delimiters>
              <v-carousel-item
                v-for="n in selected.count"
                :key="n"
                reverse-transition="fade-transition"
                transition="fade-transition"
              >
                <v-img
                  width="600"
                  max-height="90vh"
                  max-width="90vw"
                  contain
                  :src="
                    require(`@/assets/dresscode/${selected.header}/${n}.jpg`)
                  "
                ></v-img>
              </v-carousel-item>
            </v-carousel>
          </v-overlay>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    selected: "",
    categories: [
      {
        header: "dames",
        title: "Dames",
        show: false,
        count: 14,
        overlay: -1,
      },
      {
        header: "heren",
        title: "Heren",
        show: false,
        count: 10,
        overlay: -1,
      },
    ],
  }),

  computed: {
    opened() {
      return this.$route.name === "dresscode" && this.selected;
    }
  },

  methods: {
    open(cat, n) {
      cat.overlay = n - 1;
      this.$router.push({ name: 'dresscode'});
    },
    close() {
        this.selected.overlay = -1;
        // go back by one record, the same as history.back()
        this.$router.go(-1);
    },
    show(category) {
      let otherShown = false;

      this.selected = category;

      // Find if any others were shown at the moment
      for (let cat of this.categories) {
        if (cat.title !== category.title) {
          if (cat.show) {
            otherShown = true;
            cat.show = false;
          }
        }
      }

      // We can safely assume that we're not shown when others are
      // Wait a little before triggering our animation
      if (otherShown) {
        setTimeout(() => {
          category.show = true;
        }, 300);
      } else {
        category.show = !category.show;
      }
    },
  },
};
</script>

<style scoped>
.content {
  top: 100vh;
  /* top: var(--inner-height); */
  position: relative;
}
</style>
