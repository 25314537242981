<template>
  <div class="bgimg bgimg-main">
    <v-img class="image" src="@/assets/background.jpg" ref="top">
      <!-- <v-btn elevation="2" bottom fab @click="scroll" class="downbutton"
        ><v-icon>mdi-chevron-down</v-icon></v-btn
      > -->
      <count-down />
      <bounce-button @click="() => scroll('intro')" />
    </v-img>

    <c-section
      title="Wij gaan trouwen!"
      color="blue-grey lighten-4"
      ref="intro"
    >
      <register-button />

      <p>
        Vier oktober 2014 was een bijzondere dag. Onze eerste ontmoeting! Twaalf
        december 2014 werden we officieel een stel.
      </p>
      <p>
        Drieënhalf jaar later, in april 2018, waren we uitgenodigd om met gezin
        Van der Heijden op vakantie te gaan naar Gambia. Daar hebben we op de
        houtmarkt een fruitschaal meegenomen, gemaakt door Gambianen.
      </p>
      <p>
        Op de vakantie was de verjaardag van schoonvader Nici en hadden we een
        feestje met lokale vrienden. Tijdens het feestje speelde we het
        spelletje “Spin the bottle”, waarbij je een fles moest draaien en de
        persoon die aangewezen werd een vraag moest stellen. Toen Jeffrey de
        fles naar Nici draaide vroeg hij om de hand van Suzanne.
      </p>
      <p>
        Twee jaar later vroeg hij Suzanne zelf pas om haar hand. En nu, nóg een
        jaar later, is het dan eindelijk zover!
      </p>
      <p class="subheading font-weight-regular text-left">
        Gebruik de knop hierboven om je aan te melden voor onze bruiloft. Je
        kunt je aanmelding op elk moment wijzigingen.
      </p>
      <bounce-button @click="() => scroll('corona')" />
    </c-section>

    <c-section
      title="Coronamaatregelen"
      color="black white--text"
      ref="corona"
      last
    >
      <p>
        <b>Update 17 Februari:</b> Met de laatste persconferentie zijn de
        coronamaatregelen flink versoepeld. Waar het vorige week nog niet
        duidelijk was hoeveel we moesten inkrimpen, lijkt de hele bruiloft
        ongewijzigd door te kunnen gaan!
      </p>
      <p>
        Wel zijn er nog de volgende aandachtspunten:
            <ul>
              <li>Zorg dat je een mondkapje bij de hand hebt mocht deze nodig zijn op het gemeentehuis</li>
              <li>Bij Bistro de Verwennerij wordt er gevraagd naar de QR-code van je CoronaCheck app</li>
            </ul>
      </p>
      <p>
        Uiteraard vertrouwen we erop dat je zelf de verantwoordelijkheid neemt
        wanneer je klachten hebt en/of positief getest bent. Meld dan alsnog
        even af via onze ceremoniemeester.
      </p>
      <bounce-button @click="() => scroll('diet')" />
    </c-section>

    <c-section-image title="Dieetwensen" src="background.jpg" ref="diet" last>
      <image-text>
        <p>
          Het je een allergie, of volg je een bepaald dieet, geef dit dan op
          tijd door bij onze ceremoniemeester.
        </p>
        <p>
          Ook voor overige vragen over de dag zelf kun je bij haar terecht via
          deze mail:
          <a href="mailto:bruiloft@jeffreyensuzanne.nl"
            >bruiloft@jeffreyensuzanne.nl</a
          >
        </p>
      </image-text>
      <bounce-button @click="() => scroll('dresscode')" />
    </c-section-image>

    <c-section title="Dress code" color="yellow darken-3" ref="dresscode">
      <p>
        De dress code voor onze bruiloft is cocktail chique. Wil je helemaal
        gala, be my guest! Cocktail chique is minder over de top dan gala maar
        wel netter dan een normaal feestje. Voor de vrouwen hopen we op een
        feestelijke jurk of een nette broek.
      </p>

      <p>
        De mannen zijn welkom volledig in pak, maar een nette spijkerbroek met
        een colbert jasje en bijvoorbeeld een stropdas is ook voldoende.
      </p>

      <p>Voor wat meer inspiratie over wat we bedoelen kun je hier kijken.</p>
      <c-gallery></c-gallery>

      <bounce-button up @click="() => scroll('top')" />
    </c-section>
  </div>
</template>

<script>
import cSection from "@/components/Section";
import cSectionImage from "@/components/SectionImage";
import cGallery from "@/components/Gallery";
import BounceButton from "@/components/BounceButton";
import RegisterButton from "@/components/RegisterButton.vue";
import CountDown from "../components/CountDown.vue";
import ImageText from "../components/ImageText.vue";

export default {
  name: "Index2",

  components: {
    BounceButton,
    cSection,
    cSectionImage,
    cGallery,
    RegisterButton,
    CountDown,
    ImageText,
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  data: () => ({
    date: "2022-02-22",
    selectedItem: 1,
    events: [
      {
        time: "19:00",
        title: "Feest!",
        description: "DJ",
      },
      {
        time: "17:30",
        title: "Taart aansnijden",
        description: "",
      },
      {
        time: "16:45 - 17:00",
        title: "Ontvangst avondgasten",
        description: "",
      },
      {
        time: "12:00",
        title: "Lunch",
        description: "",
      },
      {
        time: "10:30",
        title: "Ceremonie",
        description: "",
      },
      {
        time: "10:00",
        title: "Ontvangst daggasten",
        description: "",
      },
    ],
  }),
  methods: {
    scroll(target) {
      window.scroll({
        behavior: "smooth",
        left: 0,
        top: this.$refs[target].$el.offsetTop,
      });
    },
  },
};
</script>

<style scoped>
.image {
  /* Fixed position gives nice slide over the picture */
  /* position: fixed; */
  position: relative;
  width: 100%;
  height: 100vh;
  /* height: var(--inner-height); */
  top: 0px;
  left: 0px;

  /* min-height: -webkit-fill-available; */
}

a[href] {
  /* Vuetify blue is too soft to read */
  color: blue;
}
</style>
