<template>
  <div class="content">
    <v-img class="image" src="@/assets/diet.jpg"> </v-img>
    <v-container width="100%" class="py-8 subcontent">
      <v-row class="px-8 py-8">
        <v-col cols="12" :md="md" class="text-center" :order="order">
          <!-- <span class="title pa-5">
            <h1 class="display-1 font-weight-bold">{{ title }}</h1>
          </span> -->
          <image-text title>
            {{ title }}
          </image-text>
        </v-col>

        <v-col cols="12" :md="md">
          <slot></slot>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>
import ImageText from "./ImageText.vue";
export default {
  components: {
    ImageText,
  },
  props: {
    title: String,
    src: String,
    last: Boolean,
  },
  computed: {
    md() {
      // return "6";
      return "12";
    },
    order() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "";
        case "sm":
          return "";
        case "md":
          return "";
      }

      // return this.last ?  "last" : "";
      return "";
    },
  },
};
</script>

<style scoped>
.content {
  /* top: 100vh; */
  /* top: var(--inner-height); */
  height: 100vh;
  /* height: var(--inner-height); */
  position: relative;
}
.subcontent {
  top: -100vh;
  /* top: calc(-1 * var(--inner-height)); */
  height: 100vh;
  /* height: var(--inner-height); */
  position: relative;
}

.container {
  max-width: 800px;
}
/*
.title {
  color: white;
  background-color: #777;
}

h1 {
  display: inline;
} */

.image {
  /* Fixed position gives nice slide over the picture */
  /* position: fixed; */
  /* position: relative; */
  /* position: absolute; */
  /* position: absolute; */
  /* position: relative; */
  width: 100%;
  height: 100vh;
  /* height: var(--inner-height); */
  opacity: 0.5;
  filter: blur(4px);
}
</style>
